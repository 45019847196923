import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Configure,
} from "react-instantsearch-dom"
import { Grid, Box, Typography } from "@mui/material"
import NoImg from "../../images/gallery/NoPreviewImage.png"
import "./searchResult.scss"

let getSlug = require("speakingurl")

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return hitCount > 0 ? (
    <Box>
      <Typography className="hitCount">
        {hitCount} result{hitCount !== 1 ? `s` : ``}
      </Typography>
    </Box>
  ) : (
    <Box>
      <Typography className="hitCount">No results found</Typography>
    </Box>
  )
})

const PageHit = ({ hit }) => {
  const articleUrl = `/post/${getSlug(hit ? hit.title : title)}`
  const addDefaultSrc = (ev) => {
    ev.target.src = NoImg
  }
  return (
    <Grid container spacing={2} className="searchResultArticle">
      <Grid item md={3}>
        <Link to={articleUrl}>
          <img
            className="articleImage"
            src={hit.preview_picture ? hit.preview_picture : NoImg}
            onError={addDefaultSrc}
          />
        </Link>
      </Grid>
      <Grid item md={9}>
        <Link to={articleUrl}>
          <Typography
            variant="h4"
            className="searchResultArticleTitle"
            gutterBottom
          >
            <Highlight attribute="title" hit={hit} tagName="mark" />
          </Typography>
          <Typography className="searchResultDescription">
            {hit.description}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  )
}

const HitsInIndex = ({ index }) => {
  return (
    <Index indexName={index.name}>
      <Configure hitsPerPage={4} />
      <Typography variant="h2" className="searchResultTitle">
        Articles
      </Typography>
      <HitCount />
      <Hits className="Hits" hitComponent={PageHit} />
    </Index>
  )
}

const SearchResult = ({ indices, className, hasFocus }) => {
  return (
    <Grid className="searchResultContainer">
      {hasFocus && (
        <Grid className="searchResults">
          {indices.map((index) => (
            <HitsInIndex index={index} key={index.name} />
          ))}
        </Grid>
      )}
    </Grid>
  )
}

export default SearchResult
