import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import Search from "../components/search"
import { Box, Grid } from "@mui/material"
import PageTitle from "../components/PageTitle/PageTitle"
import { Helmet } from "react-helmet"

function NewsFeedPage({ data, pageContext }) {
  const newsFeeds = data.newsFeeds.nodes
  const searchIndices = [
    {
      name: `CassandraFeeds`,
      title: `News Feed`,
      hitComp: `CassandraFeedsHit`,
    },
  ]
  return (
    <Layout>
      <SEO title="News Feed : " />
      <Helmet>
        <title>Cassandra Link - News Feed</title>
        <meta
          name="description"
          content="Stay updated with the latest news and updates on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:title" content="Cassandra Link - News Feed" />
        <meta
          property="og:description"
          content="Stay updated with the latest news and updates on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/news" />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cassandra Link - News Feed" />
        <meta
          name="twitter:description"
          content="Stay updated with the latest news and updates on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "News Feed - Cassandra Link",
            keywords: "News Feed, Cassandra Link",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>

      <PageTitle title="News Feed" />
      <Box
        className="articlesContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: 1600,
          marginX: "auto",
        }}
      >
        <Grid container sx={{ flexDirection: "column" }}>
          <Search
            indices={searchIndices}
            hitsContainer={"hitsContainer"}
            templateName="newsFeedTemplate"
          />
        </Grid>

        {/* <RotatingAds /> */}
      </Box>
    </Layout>
  )
}

export default NewsFeedPage

export const pageQuery = graphql`
  query NewsFeedQuery($skip: Int, $limit: Int) {
    newsFeeds: allFeedTtrs(skip: $skip, limit: $limit) {
      nodes {
        link
        title
        pubDate
        id
        author
      }
    }
  }
`
