import algoliasearch from "algoliasearch/lite"
import { createRef, default as React, useState, useMemo } from "react"
import {
  InstantSearch,
  SearchBox,
  Pagination,
  connectHits,
  ScrollTo,
} from "react-instantsearch-dom"
import { Grid, Box } from "@mui/material"
import useClickOutside from "./use-click-outside"
import CustomSearchBox from "./search-box"
import SearchResult from "./search-result"
import AlgoliaArticleCard from "../ArticleCard/AlgoliaArticleCard"
import AlgoliaNewsFeedCard from "../NewsFeedCard/AlgoliaNewsFeedCard"
import "instantsearch.css/themes/satellite.css"
import "./search.scss"

const Search = ({ indices, hitsContainer, templateName }) => {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )
  useClickOutside(rootRef, () => setFocus(false))

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      onSearchStateChange={({ query }) => setQuery(query)}
    >
      {templateName ? (
        <>
          <Grid container sx={{ width: "100%", flexDirection: "row" }}>
            <Grid item xs={12}>
              <SearchBox />
            </Grid>
            {hitsContainer ? <CustomHits /> : <CustomHits />}
            <Grid item xs={12} sx={{ justifyContent: "center" }}>
              <Pagination padding={1} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box ref={rootRef} sx={{ width: "100%" }} className="styledSearchRoot">
          <CustomSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />

          <Grid className={query && query.length > 0 ? "hasFocus" : "noFocus"}>
            <SearchResult
              indices={indices}
              onClick={() => setFocus(true)}
              hasFocus={hasFocus}
            />
          </Grid>
        </Box>
      )}
    </InstantSearch>
  )
}

const Hits = ({ hits }) => (
  <Grid container spacing={2}>
    {hits.map((hit) =>
      hit.tags ? (
        <Grid item md={4}>
          <AlgoliaArticleCard data={hit} />
        </Grid>
      ) : (
        !hit.tags && (
          <Grid item md={4}>
            <AlgoliaNewsFeedCard data={hit} />
          </Grid>
        )
      )
    )}
  </Grid>
)

const CustomHits = connectHits(Hits)

export default Search
