import React from "react"
import { navigate } from "gatsby"
import { connectSearchBox } from "react-instantsearch-dom"
import { useSearchValueContext } from "../../context/SearchValueContext"
import "./searchBox.scss"

const SearchBox = ({ refine, currentRefinement, className, onFocus }) => {
  // return the DOM output
  const { addSearchValue } = useSearchValueContext()

  const searchSubmit = (e) => {
    e.preventDefault()
    addSearchValue(currentRefinement)
    navigate("/search")
  }

  return (
    <form onSubmit={searchSubmit} className="searchForm">
      <input
        className="searchInput"
        type="text"
        placeholder="Search Cassandra Link..."
        aria-label="Search"
        onChange={(e) => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </form>
  )
}

// 2. Connect the component using the connector
const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
